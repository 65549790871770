import React, {FC, memo} from 'react';
import {useTranslation} from "react-i18next";

interface Props {
  onClick: () => void;
  title: string;
  children: any;
  idx: number;
  active: boolean;
}
const SectionItem: FC<Props> = memo(({onClick, title, children, active, idx}) => {
  const {t} = useTranslation();
  return (
    <div className={`section-4-item${active ? ' active' : ''}`} id={`section-4-item_${idx}`}>
      <div className="section-4-item-head" onClick={onClick}>
        <h4>{t(`faq:${title}`)}</h4>
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 12L36 36M36 36V12M36 36H12" stroke="white" strokeWidth="4" strokeLinecap="round"
                strokeLinejoin="round"/>
        </svg>
      </div>
      <div className="section-4-item-content">
        {children}
      </div>

    </div>
  );
})

export default SectionItem;