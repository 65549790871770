import React, {FC} from 'react';
import owner_slide_1_ru from '../../../assets/images/slides/screen1.png';
import owner_slide_2_ru from '../../../assets/images/slides/screen2.png';
import owner_slide_3_ru from '../../../assets/images/slides/screen3.png';
import owner_slide_4_ru from '../../../assets/images/slides/screen4.png';
import owner_slide_5_ru from '../../../assets/images/slides/screen5.png';

import author_slide_1_ru from '../../../assets/images/slides/author/i01ru.png';
import author_slide_2_ru from '../../../assets/images/slides/author/i02ru.png';
import author_slide_3_ru from '../../../assets/images/slides/author/i03ru.png';
import author_slide_4_ru from '../../../assets/images/slides/author/i04ru.png';
import author_slide_1_en from '../../../assets/images/slides/author/i01en.png';
import author_slide_2_en from '../../../assets/images/slides/author/i02en.png';
import author_slide_3_en from '../../../assets/images/slides/author/i03en.png';
import author_slide_4_en from '../../../assets/images/slides/author/i04en.png';

import scout_slide_1_ru from '../../../assets/images/slides/scout/s01ru.png';
import scout_slide_2 from '../../../assets/images/slides/scout/s02.png';
import scout_slide_3_ru from '../../../assets/images/slides/scout/s03ru.png';
import scout_slide_4_ru from '../../../assets/images/slides/scout/s04ru.png';
import scout_slide_1_en from '../../../assets/images/slides/scout/s01en.png';
import scout_slide_3_en from '../../../assets/images/slides/scout/s03en.png';
import scout_slide_4_en from '../../../assets/images/slides/scout/s04en.png';

import arrow_left from '../../../assets/images/arrow_left.png';
import arrow_right from '../../../assets/images/arrow_right.png';
import {TRole} from "../../../utils/rest";
import {useTranslation} from "react-i18next";

const screens: any = {
  owner: {
    ru: [owner_slide_1_ru, owner_slide_2_ru, owner_slide_3_ru, owner_slide_4_ru, owner_slide_5_ru],
    en: [owner_slide_1_ru, owner_slide_2_ru, owner_slide_3_ru, owner_slide_4_ru, owner_slide_5_ru],
  },
  author: {
    ru: [author_slide_1_ru, author_slide_2_ru, author_slide_3_ru, author_slide_4_ru, owner_slide_5_ru],
    en: [author_slide_1_en, author_slide_2_en, author_slide_3_en, author_slide_4_en, owner_slide_5_ru],
  },
  scout: {
    ru: [scout_slide_1_ru, scout_slide_2, scout_slide_3_ru, scout_slide_4_ru, owner_slide_5_ru],
    en: [scout_slide_1_en, scout_slide_2, scout_slide_3_en, scout_slide_4_en, owner_slide_5_ru],
  },
}

interface Props {
  children: any;
  idx: number;
  reverse?: boolean;
  first?: boolean;
  role: TRole;
}

const SectionItem: FC<Props> = ({children, idx, reverse, first, role}) => {
  const {i18n} = useTranslation();
  return (
    <div className={`section-3-item${reverse ? ' flex-row-reverse' : ''}`}>
      <div className="section-3-item-left text-24">
        <div className='section-3-item-left-text'>
          {children}
        </div>
        <div className="section-3-item-center">
          <div className='section-3-number-wrap'>
            <div className={`section-3-number`}>
              {idx + 1}
            </div>
          </div>
        </div>
        {!first
          ?
          <img src={!reverse ? arrow_right : arrow_left} alt="arrow" className='section-3-item-arrow'/>
          :
          null
        }
      </div>
      <div className="section-3-item-right">
        <img src={screens[role][i18n.language][idx]} alt="screenshot" className='section-3-screenshot'/>
      </div>
    </div>
  );
}

export default SectionItem;