import React, {FC, useRef, useState} from 'react';
import {API} from '../../utils/api';
import Button from '../../components/button';
import {toast} from '../../utils/utils';
import arrow from '../../assets/images/arrow_right.png';
import {useTranslation} from "react-i18next";
import {TRole} from "../../utils/rest";

interface Props {
  setToken: (token: string|null) => void;
  role: TRole;
}

const RegistrationForm: FC<Props> = ({setToken, role}) => {
  const {t} = useTranslation();
  const email: any = useRef(null);
  const password: any = useRef(null);
  const telegram: any = useRef(null);
  const channels: any = useRef(null);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (loading) return null;
    setLoading(true);
    try {
      const {token, id} = await API.Users.register({
        role,
        email: email.current.value,
        password: role === 'owner' ? Math.random().toString() : password?.current.value,
        telegram: telegram.current.value,
        channels: channels?.current?.value?.split('\n'),
        source: window.localStorage.getItem('source'),
        ref: window.localStorage.getItem('ref'),
      });
      // @ts-ignore
      window.ym(91359429, 'reachGoal', 'reg');
      if (role !== 'owner') {
        setTimeout(() => {
          window.location.href = `https://my.monetize.club/as/${token}`
        }, 500);
      } else {
        localStorage.setItem('token', token);
        localStorage.setItem('userId', String(id));
        setToken(token);
      }
    } catch (e: any) {
      toast(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="registration" onSubmit={handleSubmit}>
      <div className="row align-items-end">
        <div className="col-12 col-xl-6">
          <div className="form-group">
            <label>{t('EMAIL')}</label>
            <input ref={email} required type="email" className="form-control"
                   placeholder={t('REG_FORM_PLACEHOLDER_1') || ''}/>
          </div>
        </div>
        <div className="col-12 col-xl-6">
          <div className="form-group">
            <label>{t('REG_FORM_LABEL_1')}</label>
            <input ref={telegram} required type="text" className="form-control" placeholder="@telegram"/>
          </div>
        </div>
      </div>
      {role === 'owner'
        ?
        <div className="form-group pb-4">
          <label>{t('REG_FORM_LABEL_2')}</label>
          <textarea
            ref={channels}
            required
            rows={3}
            className="form-control"
            placeholder="https://www.youtube.com/@..."
          />
        </div>
        :
        <div className="form-group">
          <label>{t('PASSWORD')}</label>
          <input ref={password} required type="password" className="form-control"
                 placeholder={t('PASSWORD_PLACEHOLDER') || ''}/>
        </div>
      }

      <Button type="submit" className="btn-animate mt-4 align-self-center" loading={loading}>
        <span>{t('CREATE_ACC')}</span>
        <img src={arrow} alt="arrow" className="reg-btn-arrow"/>
      </Button>
    </form>
  );
};

export default RegistrationForm;
