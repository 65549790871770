import React from 'react';
import {useTranslation} from "react-i18next";

const SectionContent4 = () => {
  const {t} = useTranslation();
  return (
    <>
      <p className='mb-3'>{t('faq:SECTION_4_ITEM_4_TEXT_1')}</p>
      {/*<div className="row pb-3">*/}
      {/*  <div className="col-12 col-lg-4">*/}
      {/*    <ul>*/}
      {/*      <li>{t('SECTION_4_ITEM_4_TEXT_1_1')}</li>*/}
      {/*      <li>{t('SECTION_4_ITEM_4_TEXT_1_2')}</li>*/}
      {/*      <li>{t('SECTION_4_ITEM_4_TEXT_1_3')}</li>*/}
      {/*      <li>{t('SECTION_4_ITEM_4_TEXT_1_4')}</li>*/}
      {/*      <li>{t('SECTION_4_ITEM_4_TEXT_1_5')}</li>*/}
      {/*    </ul>*/}
      {/*  </div>*/}
      {/*  <div className="col-12 col-lg-4">*/}
      {/*    <ul>*/}
      {/*      <li>{t('SECTION_4_ITEM_4_TEXT_1_6')}</li>*/}
      {/*      <li>{t('SECTION_4_ITEM_4_TEXT_1_7')}</li>*/}
      {/*      <li>{t('SECTION_4_ITEM_4_TEXT_1_8')}</li>*/}
      {/*      <li>{t('SECTION_4_ITEM_4_TEXT_1_9')}</li>*/}
      {/*      <li>{t('SECTION_4_ITEM_4_TEXT_1_10')}</li>*/}
      {/*    </ul>*/}
      {/*  </div>*/}
      {/*  <div className="col-12 col-lg-4">*/}
      {/*    <ul>*/}
      {/*      <li>{t('SECTION_4_ITEM_4_TEXT_1_11')}</li>*/}
      {/*      <li>{t('SECTION_4_ITEM_4_TEXT_1_12')}</li>*/}
      {/*      <li>{t('SECTION_4_ITEM_4_TEXT_1_13')}</li>*/}
      {/*      <li>{t('SECTION_4_ITEM_4_TEXT_1_14')}</li>*/}
      {/*      <li>{t('SECTION_4_ITEM_4_TEXT_1_15')}</li>*/}
      {/*    </ul>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<p>{t('SECTION_4_ITEM_4_TEXT_2')}</p>*/}
    </>
  );
}

export default SectionContent4;