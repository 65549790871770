import React, {useEffect, useLayoutEffect, useState} from 'react';

import {Navigate, Route, Routes} from 'react-router-dom';
import HomePage from './pages/HomePage';
import RegistrationPage from './pages/RegistrationPage';
import Ref from './pages/Ref';
import {useTranslation} from "react-i18next";
import {getLang} from "./utils/utils";
import youtuber_theme from "./theme/youtuber_theme.json";
import musicians_theme from "./theme/musicians_theme.json";
import scout_theme from "./theme/scout_theme.json";
import {TRole} from "./utils/rest";


function setColors(theme: object) {
  document.documentElement.style.setProperty('--transition', 'none');
  document.documentElement.style.setProperty('--transition-1s', 'none');
  Object.entries(theme).forEach(([key, color]) => {
    document.documentElement.style.setProperty(key, color);
  })
  setTimeout(() => {
    document.documentElement.style.setProperty('--transition', 'all .3s ease');
    document.documentElement.style.setProperty('--transition-1s', 'all 1s ease')
  }, 0);
}


function App() {
  const {i18n} = useTranslation();
  const [role, setRole] = useState<TRole>();

  useLayoutEffect(() => {
    const subDomain = window.location.hostname.split('.')[0];
    if (subDomain === 'music') {
      setColors(musicians_theme)
      setRole('author');
    } else if (subDomain === 'scout') {
      setColors(scout_theme)
      setRole('scout');
    } else {
      setRole('owner');
      setColors(youtuber_theme)
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(getLang())
    const urlParams = new URLSearchParams(window.location.search);
    let source = urlParams.get('utm_source') || urlParams.get('s');
    if (source) {
      window.localStorage.setItem('source', source);
    }

  }, []);

  if (!role) return null;
  return (
    <Routes>
      <Route path="/" element={<HomePage role={role}/>}/>
      <Route path="/r/:id" element={<Ref/>}/>
      <Route path="/registration" element={<RegistrationPage role={role}/>}/>
      <Route path="*" element={<Navigate to="/" replace={true}/>}/>
    </Routes>
  );
}

export default App;
