import React from 'react';
import {useTranslation} from "react-i18next";

const SectionContent3 = () => {
  const {t} = useTranslation();
  return (
    <p>{t('faq:SECTION_4_ITEM_3_TEXT')}</p>
  );
}

export default SectionContent3;