import React from 'react';
import {useTranslation} from "react-i18next";

const SectionContent5 = () => {
  const {t} = useTranslation();
  return (
   <p>{t('faq:SECTION_4_ITEM_5_TEXT')}</p>
  );
}

export default SectionContent5;