import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ru from './translates/ru/ru.json';
import en from './translates/en/en.json';

import youtuber_ru from './translates/ru/youtuber_ru.json';
import owner_ru from './translates/ru/owner_ru.json';
import musicians_ru from './translates/ru/musicians_ru.json';
import scout_ru from './translates/ru/scout_ru.json';

import youtuber_en from './translates/en/youtuber_en.json';
import owner_en from './translates/en/owner_en.json';
import musicians_en from './translates/en/musicians_en.json';
import scout_en from './translates/en/scout_en.json';
import faq_ru from './translates/ru/faq_ru.json';
import faq_en from './translates/en/faq_en.json';

import {getLang} from "./utils/utils";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  ru:{
    translation: ru,
    owner: owner_ru,
    author: musicians_ru,
    scout: scout_ru,
    faq: faq_ru,
  },
  en: {
    translation: en,
    owner: owner_en,
    author: musicians_en,
    scout: scout_en,
    faq: faq_en,
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    languages: ['en'],
    lng: getLang(), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
