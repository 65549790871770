import React, {FC, useCallback, useEffect, useLayoutEffect, useState} from 'react';
import Section_1 from "./Section_1";
import Section_2 from "./Section_2";
import Section_3 from "./Section_3";
import Section_4 from "./Section_4";
import Header from "../../components/Header";
import Sider from "../../components/Sider";
import Footer from "../../components/Footer";
import {TRole} from "../../utils/rest";
import {useTranslation} from "react-i18next";

export type TTranslatePrefix = 'author'|'scout'|'owner';

interface Props {
  role: TRole;
}


const HomePage: FC<Props> = ({role}) => {
  const {i18n} = useTranslation();
  const [translatePrefix, setTranslatePrefix] = useState<TTranslatePrefix>('owner');
  const [siderVisible, setSiderVisible] = useState(false);

  useLayoutEffect(() => {
    setTranslatePrefix(role);
  }, [role]);

  const handleCloseSider = () => {
    setSiderVisible(false);
  }

  return (
    <div className={`land${siderVisible ? ' sider-visible' : ''} role-${role}`}>
      <Sider closeSider={handleCloseSider} role={role}/>
      <div className="land-wrapper" onClick={siderVisible ? handleCloseSider : undefined}>
        <div className='circle-bg'/>
        <Header onClick={() => setSiderVisible(true)}  role={role}/>
        <main className='container d-flex flex-column align-items-center position-relative'>
          <Section_1 translatePrefix={translatePrefix} role={role}/>
          {(role === 'owner' && i18n.language === 'ru') || role === 'scout'
            ?
            <Section_2 translatePrefix={translatePrefix} role={role}/>
            :
            null
          }
          <div className='position-relative sections-wrap'>
            <div className='youtube-bg'><img src={require('../../assets/images/youtube.png')} alt=""/></div>
            <Section_3 translatePrefix={translatePrefix} role={role}/>
            {role === 'owner'
              ?
              <Section_4/>
              :
              null
            }

          </div>
        </main>
        <Footer role={role}/>
      </div>
    </div>
  );
}

export default HomePage;