import React, {FC, useLayoutEffect, useState} from 'react';
import './registration.css';
import RegistrationForm from './RegistrationForm';
import RegistrationQuestionnaire from './RegistrationQuestionnaire';
import RegistrationSider from "./RegistrationSider";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {TRole} from "../../utils/rest";

interface Props {
  role: TRole;
}

const RegistrationPage: FC<Props> = ({role}) => {
  const {t} = useTranslation();
  const [token, setToken] = useState<string|null>(window.localStorage.getItem('token') || '');
  const [successForm, setSuccessForm] = useState(false);

  useLayoutEffect(() => {
    //@ts-ignore
    window.scrollTo({top: 0, behavior: "instant"})
  }, []);

  return (
    <div className={'registration-page'}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-5 position-relative">
            <RegistrationSider token={token} successForm={successForm} role={role}/>
          </div>
          <div className="col-12 col-lg-6 col-xl-7">
            {successForm ? null
              :
              <div className='registration-right'>
                {token ? <RegistrationQuestionnaire token={token} setSuccessForm={setSuccessForm}/> :
                  <RegistrationForm setToken={setToken} role={role}/>}
              </div>
            }
          </div>
        </div>
        <Link to='/' className='d-block d-lg-none text-center text-decoration-underline mt-4'>{t('BACK_TO_SITE')}</Link>
      </div>
    </div>
  )
};

export default RegistrationPage;
