import React, {FC} from 'react';
import {ReactSVG} from 'react-svg'
import scout from '../../assets/images/icons/scout.svg';
import musician from '../../assets/images/icons/musician.svg';
import youtuber from '../../assets/images/icons/youtuber.svg';
import {useTranslation} from "react-i18next";
import {domain} from "../../utils/api";
import {TRole} from "../../utils/rest";

const rolesMap: { role: TRole, icon: any, title: string }[] = [
  {role: 'owner', icon: youtuber, title: 'YOUTUBERS'},
  {role: 'author', icon: musician, title: 'MUSICIANS'},
  {role: 'scout', icon: scout, title: 'SCOUTS'},
]

const subdomainMap = {
  owner: '',
  author: 'music.',
  scout: 'scout.',
}

interface Props {
  role: TRole;
}

const HeaderRoles: FC<Props> = ({role}) => {
  const {t} = useTranslation();

  return (
    <div className='header-roles'>
      {rolesMap.map((item) => {
        if (item.role === role) return null;
        return (
          <a href={`${window.location.protocol}//${subdomainMap[item.role]}${domain}`} key={item.role} className={`header-roles-item ${item.role}`} >
            <div className='header-roles-item-icon'><ReactSVG src={item.icon}/></div>
            <span className='ps-2'>{t(item.title)}</span>
          </a>
        )
      })}
    </div>
  );
}

export default HeaderRoles;