import React, {FC} from 'react';
import './section_1.css';
import IconImage from "../../../components/IconImage";
import money from '../../../assets/images/icons/money.png';
import arrow from '../../../assets/images/arrow_top.png';
import girl_img from '../../../assets/images/girl.png';
import musician_img from '../../../assets/images/musician.png';
import scout_img from '../../../assets/images/scout.png';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {TTranslatePrefix} from "../HomePage";
import {TRole} from "../../../utils/rest";

const imagesMap = {
  author: musician_img,
  scout: scout_img,
  owner: girl_img,
}

interface Props {
  translatePrefix: TTranslatePrefix;
  role: TRole;
}

const Section_1: FC<Props> = ({translatePrefix, role}) => {
  const {t} = useTranslation();
  return (
    <section className='section-1' id='section-1'>
      <div className="section-1-bg">
        <img src={imagesMap[role]} alt="bg"/>
      </div>
      <div className='text-24 section-1-title' dangerouslySetInnerHTML={{__html: t(`${translatePrefix}:PAGE_TITLE`)}}/>
      <div className='d-lg-flex w-100 justify-content-between'>
        <div>
          <h2 className='section-text'>{t('WE_HELP')}<br/> <span>{t('EARN')}</span></h2>
          <div className='d-flex mt-5 justify-content-center pb-4 mb-2'>
            <IconImage icon={money} size={60}/>
            <div className='text-24 ps-3 ms-lg-2 text-left' dangerouslySetInnerHTML={{__html: t(`${translatePrefix}:HELP_TO`)}}/>
          </div>
        </div>
        <div>
          <div className='title' dangerouslySetInnerHTML={{__html: t(`${translatePrefix}:MONETIZE_GOOD`)}}></div>
          <div className='pt-2 pt-lg-3' dangerouslySetInnerHTML={{__html: t(`${translatePrefix}:MONETIZE_GOOD_1`)}}></div>
        </div>
      </div>
      <Link to='/registration' className={'btn btn-animate'}>
        <span style={{transform: 'rotate(-5deg)'}}>{t('START_EARN')}</span>
        <img src={arrow} alt="arrow" className='section-1-btn-arrow'/>
      </Link>
      <div className="row section-1-bottom">
        <div className="col-12 col-lg-4 pb-3 pb-lg-0">
          <div className="section-1-item">
            <div className="title pb-3" dangerouslySetInnerHTML={{__html: t(`${translatePrefix}:SECTION_1_1_TITLE`)}}/>
            <div>{t(`${translatePrefix}:SECTION_1_1_TEXT`)}</div>
          </div>
        </div>
        <div className="col-12 col-lg-4 pb-3 pb-lg-0">
          <div className="section-1-item">
            <div className="title pb-3" dangerouslySetInnerHTML={{__html: t(`${translatePrefix}:SECTION_1_2_TITLE`)}}/>
            <div>{t(`${translatePrefix}:SECTION_1_2_TEXT`)}</div>
          </div>
        </div>
        <div className="col-12 col-lg-4 pb-3 pb-lg-0">
          <div className="section-1-item">
            <div className="title pb-3" dangerouslySetInnerHTML={{__html: t(`${translatePrefix}:SECTION_1_3_TITLE`)}}/>
            <div>{t(`${translatePrefix}:SECTION_1_3_TEXT`)}</div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section_1;