import React, { ButtonHTMLAttributes, FC } from 'react';
import './button.css';
import Spinner from '../spinner';

interface Props extends ButtonHTMLAttributes<any> {
  children: any;
  loading?: boolean;
  className?: string;
  disabled?: boolean;
}

const Button: FC<Props> = ({ children, loading, disabled, className, ...props }) => {
  return (
    <button className={`btn${loading ? ' loading' : ''}${disabled ? ' disabled' : ''} ${className || ''}`} {...props}>
      <div className="btn-content">{children}</div>
      {loading ? <Spinner /> : null}
    </button>
  );
};

export default Button;
