import React, {FC, useState} from 'react';
import './section_2.css';
import man from '../../../assets/images/man.png';
import extension_ru from "../../../assets/images/extension_ru.png";
import extension_en from "../../../assets/images/extension_en.png";
import arrow from "../../../assets/images/arrow_section_2.png";
import {extension_href, youtube_id} from "../../../data/constants";
import {Modal} from 'react-responsive-modal';
import {useTranslation} from "react-i18next";
import {TTranslatePrefix} from "../HomePage";
import {TRole} from "../../../utils/rest";


const imagesMap: any = {
  scout: {
    ru: extension_ru,
    en: extension_en,
    translates: 1,
  },
  owner: man,
}

interface Props {
  translatePrefix: TTranslatePrefix;
  role: TRole;
}

const Section_2: FC<Props> = ({translatePrefix, role}) => {
  const {t, i18n} = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (role === 'scout') {

    } else {
      setOpen(true);
    }
  }
  const onCloseModal = () => setOpen(false);
  return (
    <>
      <section className='section-2' id='section-2'>
        <div className='d-flex flex-column-reverse flex-xl-row'>
          <div className='section-2-day-text d-block d-xl-none'
               dangerouslySetInnerHTML={{__html: t(`${translatePrefix}:SECTION_2_PARTNER`)}}/>
          <div className='section-2-bg'>
            <img src={imagesMap[role]?.translates ? imagesMap[role][i18n.language] : imagesMap[role]} alt="bg" className='section-2-bg-img'/>
            <div className={'btn btn-animate d-flex'} onClick={handleClick}>
              {role === 'scout'
                ?
                <a href={extension_href} target={'_blank'}
                   className='download-extension'>{t(`${translatePrefix}:WATCH_VIDEO`)}</a>
                :
                <svg width="43" height="47" viewBox="0 0 43 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M39.3535 20.1825C40.0512 20.5078 40.1351 21.4664 39.5045 21.908L8.29059 43.7641C7.66001 44.2057 6.78791 43.799 6.72082 43.0321L3.39974 5.07203C3.33265 4.30516 4.12087 3.75323 4.81855 4.07857L39.3535 20.1825Z"
                    stroke="white" strokeWidth="6"/>
                </svg>
              }
              <div className='section-2-btn-arrow'>
                <img src={arrow} alt="arrow"/>
                {role === 'scout'
                  ?
                  null
                  :
                  <div className='muted'>{t(`${translatePrefix}:WATCH_VIDEO`)}</div>
                }
              </div>
            </div>
          </div>

          <div className='section-2-right'>
            <h2 className='section-text' dangerouslySetInnerHTML={{__html: t(`${translatePrefix}:SECTION_2_TITLE`)}}/>
            <div className='section-2-right-bottom'>
              <div className='pt-3 section-2-day bold text-yellow'>{t(`${translatePrefix}:7_DAYS`)}</div>
              <div className='text-24 text-yellow pt-3'
                   dangerouslySetInnerHTML={{__html: t(`${translatePrefix}:SECTION_2_TEXT`)}}/>
              <div className='section-2-day-text d-none d-xl-block'
                   dangerouslySetInnerHTML={{__html: t(`${translatePrefix}:SECTION_2_PARTNER`)}}/>
            </div>
          </div>
        </div>
      </section>
      {open
        ?
        <Modal
          open={open}
          onClose={onCloseModal}
          center
          showCloseIcon={false}
        >
          <div className='position-relative'>
            <div className='pointer position-absolute' onClick={onCloseModal} style={{top: -56, right: -56}}>
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="15" y="11" width="48" height="6" rx="3" transform="rotate(45 15 11)" fill="#FFC700"/>
                <rect x="11" y="45" width="48" height="6" rx="3" transform="rotate(-45 11 45)" fill="#FFC700"/>
              </svg>
            </div>
            <iframe src={`https://www.youtube.com/embed/${youtube_id}`} className='youtube-iframe'/>
          </div>
        </Modal>
        :
        null
      }
    </>

  );
}

export default Section_2;