import React, {FC, useCallback, useEffect, useState} from 'react';
import {API} from '../../utils/api';
import RegSwitch from './RegSwitch';
import Button from '../../components/button';
import {toast} from '../../utils/utils';
import arrow from "../../assets/images/arrow_right.png";
import {useTranslation} from "react-i18next";

interface Props {
  token: string;
  setSuccessForm: (status: boolean) => void;
}

export interface UserForm {
  views: string;
  geo: string;
  theme: string;
  monetize_yt: boolean;
  monetize_alt: string|null;
}

const RegistrationQuestionnaire: FC<Props> = ({token, setSuccessForm}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<UserForm>({
    geo: '',
    monetize_alt: null,
    views: '',
    monetize_yt: false,
    theme: '',
  });

  useEffect(() => {
    getUser()
  }, []);

  const getUser = async () => {
    try {
      // const user = API.Users.
    } catch (e) {

    }
  }

  const handleChange = useCallback(
    (data: any) => {
      setForm((prevForm: any) => {
        return {...prevForm, ...data};
      });
    },
    [setForm]
  );

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (loading) return null;
    setLoading(true);
    try {
      await API.Users.form({token, form});
      setSuccessForm(true);
      window.localStorage.removeItem('token');
      // @ts-ignore
      window.ym(91359429, 'reachGoal', 'form');
    } catch (e: any) {
      toast(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="registration" onSubmit={handleSubmit}>
      <div className="form-group">
        <label dangerouslySetInnerHTML={{__html: t('FORM_LABEL_1')}}/>
        <input
          value={form.views}
          onChange={(e) => handleChange({views: e.target.value})}
          required
          type="text"
          className="form-control"
          placeholder="500k"
        />
      </div>
      <div className="form-group">
        <label dangerouslySetInnerHTML={{__html: t('FORM_LABEL_2')}}/>
        <input
          value={form.geo}
          onChange={(e) => handleChange({geo: e.target.value})}
          required
          type="text"
          className="form-control"
          placeholder={t('FORM_PLACEHOLDER_1') || ''}
        />
      </div>
      <div className="form-group">
        <label>{t('FORM_LABEL_3')}</label>
        <textarea
          value={form.theme}
          onChange={(e) => handleChange({theme: e.target.value})}
          required
          rows={3}
          className="form-control"
          placeholder={t('FORM_PLACEHOLDER_2') || ''}
        />
      </div>
      <div className="form-group">
        <label>{t('FORM_LABEL_4')}</label>
        <RegSwitch
          text={[t('YES'), t('NO')]}
          value={form.monetize_yt ? 'Да' : 'Нет'}
          onChange={(e) => handleChange({monetize_yt: e === t('YES')})}
        />
      </div>
      <div className="form-group">
        <label dangerouslySetInnerHTML={{__html: t('FORM_LABEL_5')}}/>
        <RegSwitch
          text={[t('YES'), t('NO')]}
          value={form.monetize_alt === null ? 'Нет' : 'Да'}
          onChange={(e) => handleChange({monetize_alt: e === t('YES') ? '' : null})}
        />
      </div>
      {form.monetize_alt !== null ? (
        <textarea
          value={form.monetize_alt || ''}
          onChange={(e) => handleChange({monetize_alt: e.target.value})}
          required
          rows={3}
          className="form-control mt-3"
          placeholder={t('FORM_PLACEHOLDER_3') || ''}
        />
      ) : null}
      <div className='mt-4 pt-2'>
        <Button type="submit" className="btn-animate align-self-center" loading={loading}>
          <span>{t('SEND')}</span>
          <img src={arrow} alt="arrow" className='reg-btn-arrow'/>
        </Button>
      </div>
    </form>
  );
};

export default RegistrationQuestionnaire;
