import React, {FC} from 'react';

interface Props {
  icon: any;
  size?: number;
  className?: string;
}

const IconImage: FC<Props> = ({icon, size = 48, className}) => {
  return (
    <img
      src={icon}
      alt="icon"
      style={{width: size, height: size, minWidth: size}}
      className={className || ''}
    />
  );
}

export default IconImage;