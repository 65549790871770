import React, {FC} from 'react';
import './section-3.css';
import SectionItem from "./SectionItem";
import {useTranslation} from "react-i18next";
import {TTranslatePrefix} from "../HomePage";
import {TRole} from "../../../utils/rest";

interface Props {
  translatePrefix: TTranslatePrefix;
  role: TRole;
}

const Section_3:FC<Props> = ({translatePrefix, role}) => {
  const {t} = useTranslation();
  return (
    <section className='section-3' id='section-3'>
      <h2 className="section-text">{t('HOW_DOES_IT_WORK')}</h2>
      <div className='section-3-items'>
        <SectionItem idx={0} first role={role}>
          <div dangerouslySetInnerHTML={{__html: t(`${translatePrefix}:SECTION_3_TEXT_1`)}} />
        </SectionItem>
        <SectionItem idx={1} reverse role={role}>
          <div dangerouslySetInnerHTML={{__html: t(`${translatePrefix}:SECTION_3_TEXT_2`)}} />
        </SectionItem>
        <SectionItem idx={2} role={role}>
          <div dangerouslySetInnerHTML={{__html: t(`${translatePrefix}:SECTION_3_TEXT_3`)}} />
        </SectionItem>
        <SectionItem idx={3} reverse role={role}>
          <div dangerouslySetInnerHTML={{__html: t(`${translatePrefix}:SECTION_3_TEXT_4`)}} />
        </SectionItem>
        <SectionItem idx={4} role={role}>
          <div dangerouslySetInnerHTML={{__html: t(`${translatePrefix}:SECTION_3_TEXT_5`)}} />
        </SectionItem>
      </div>
    </section>
  );
}

export default Section_3;