import React, {FC} from 'react';
import './header.css'
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import HeaderRoles from "./HeaderRoles";
import LangSelect from "./LangSelect";
import Logo from "../Logo";
import {TRole} from "../../utils/rest";


interface Props {
  onClick: () => void;
  role: TRole;
}

const Header: FC<Props> = ({onClick, role}) => {
  const {t} = useTranslation();
  const handleBurgerClick = (e: any) => {
    e.stopPropagation();
    onClick();
  }
  return (
    <header>
      <div className='d-flex align-items-center'>
      <div className={'header-burger'} onClick={handleBurgerClick}>
        <div className="header-burger-el"/>
        <div className="header-burger-el header-burger-center"/>
        <div className="header-burger-el"/>
      </div>
         <HeaderRoles role={role} />
      </div>
      <Logo role={role} className='header-logo'/>
      <div className='header-right'>
        <LangSelect />
        <Link to='/registration' className='btn btn-primary'>{t('SIGN_UP')}</Link>
        <a href='https://my.monetize.club/' className='login'>
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25 11.9999L36 11.9998L36 35.9999L25 36" stroke="white" strokeWidth="4" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M12 24H23.9411M23.9411 24L18.9411 18.9999M23.9411 24L18.9411 29" stroke="white" strokeWidth="4"
                  strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <div className='bold ps-2'>{t('LOGIN')}</div>
        </a>
      </div>
    </header>
  );
}

export default Header;