import React from 'react';
import {useTranslation} from "react-i18next";

const SectionContent2 = () => {
  const {t} = useTranslation();
  return (
    <>
      <p className='mb-3' dangerouslySetInnerHTML={{__html: t('faq:SECTION_4_ITEM_2_TEXT_1')}}/>
      <p className='mb-3' dangerouslySetInnerHTML={{__html: t('faq:SECTION_4_ITEM_2_TEXT_2')}}/>
      <p className='mb-3'>{t('faq:SECTION_4_ITEM_2_TEXT_3')}</p>
      <p className='mb-3'>{t('faq:SECTION_4_ITEM_2_TEXT_4')}</p>
      <p>{t('faq:SECTION_4_ITEM_2_TEXT_5')}</p>
    </>
  );
}

export default SectionContent2;