import React, {FC} from 'react';
import useScrollspy from "../../hook/useScrollspy";
import './sider.css';
import {tg_support_href} from "../../data/constants";
import {useTranslation} from "react-i18next";
import {TRole} from "../../utils/rest";

const menus = {
  owner: [{id: 'section-1', title: 'HOME'}, {id: 'section-2', title: 'GUARANTEES'}, {id: 'section-3', title: 'HOW_IT_WORK'}, {id: 'section-4', title: 'FAQ'}],
  author: [{id: 'section-1', title: 'HOME'},{id: 'section-3', title: 'HOW_IT_WORK'}],
  scout: [{id: 'section-1', title: 'HOME'}, {id: 'section-2', title: 'EXTENSION'}, {id: 'section-3', title: 'HOW_IT_WORK'}],
}

interface Props {
  closeSider: () => void;
  role: TRole;
}

const Sider: FC<Props> = ({closeSider, role}) => {
  const {t} = useTranslation();
  const activeId = useScrollspy(['section-1', 'section-2', 'section-3', 'section-4'], 180);

  const handleClick = (id: string) => (e: any) => {
    e.preventDefault();
    closeSider();
    const element: any = document.getElementById(id);
    const offset = 100;
    const bodyRect: any = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <aside className={`sider`}>
      <div className='sider-head'>
        <div className='pointer' onClick={closeSider}>
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="15" y="11" width="48" height="6" rx="3" transform="rotate(45 15 11)" fill="black"/>
            <rect x="11" y="45" width="48" height="6" rx="3" transform="rotate(-45 11 45)" fill="black"/>
          </svg>
        </div>
        <div className='ps-3 text-24 bold'>{t('MENU')}</div>
      </div>
      <nav>
        <ul>
          {menus[role]?.map((item, i) => {
            return <li key={i}>
              <a
                href={`#${item.id}`}
                className={`text-24 bold ${item.id === activeId ? 'active' : ''}`}
                onClick={handleClick(item.id)}
              >
                {t(item.title)}
              </a>
            </li>
          })}
        </ul>
      </nav>
      <div>
        <div>{t('SUPPORT')}:</div>
        <a href={tg_support_href} target='_blank' rel="noreferrer">@monetizesupport</a>
      </div>
    </aside>
  );
}

export default Sider;