import {toast as toastify} from 'react-toastify';

const toastConfig = {
  position: 'top-right',
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
};

export function toast(text: string, type = 'error') {
  if (!text) return null;
  // @ts-ignore
  toastify[type](text, toastConfig);
  // @ts-ignore
  // toastify(Toast, {...toastConfig, data: {type, text}});
}

const getStartUrl = (): string => {
  const urlParams = new URLSearchParams(window.location.search);
  let source = urlParams.get('utm_source') || urlParams.get('s');
  let ref = window.localStorage.getItem('ref');
  if (!source) {
    source = window.location.host.split('.')[0];
  }
  return `https://start.monetize.club/?utm_source=${source}${ref ? `&ref=${ref}` : ''}`;
};
export const getLang = (ln?: string) => {
  //@ts-ignore
  if(!ln) ln =  localStorage.getItem('ln') || (window?.navigator?.userLanguage || window.navigator.language).substring(0, 2);
  const rusList = ['ru', 'uk', 'kk', 'be', 'az', 'lv', 'ee', 'tr', 'ka', 'hy', 'uz', 'mo', 'bg'];
  if (rusList.includes(ln || '')) return 'ru';
  return 'en';
}

export {getStartUrl};
