import React, {FC} from 'react';
import logo from "../assets/images/logo.png";
import logo_white from "../assets/images/logo_white.png";
import {TRole} from "../utils/rest";

const logoMap = {
  author: logo,
  scout: logo_white,
  owner: logo,
}


interface Props {
  role: TRole;
  className?: string;
}

const Logo: FC<Props> = ({role, className}) => {
  return (
    <img src={logoMap[role]} alt="logo" className={className || ''}/>
  );
}

export default Logo;