import React, {FC} from 'react';
import './footer.css';
import logo from '../../assets/images/logo_muted.png';
import logo_white from '../../assets/images/logo_muted_white.png';
import tg from '../../assets/images/icons/tg.png';
import {tg_href, tg_support_href} from "../../data/constants";
import {useTranslation} from "react-i18next";
import {TRole} from "../../utils/rest";

const logoMap = {
  author: logo,
  scout: logo_white,
  owner: logo,
}

interface Props {
  role: TRole;
}

const Footer: FC<Props> = ({role}) => {
  const {t} = useTranslation();
  return (
    <footer>
      <div className="row align-items-center w-100">
        <div className="col-12 col-xl-3">
          <img src={logoMap[role]} alt="logo" className='footer-logo'/>
        </div>
        <div className="col-12 col-xl-4 muted pt-3 pt-xl-0 position-relative">
          {role === 'owner'
            ?
            <div dangerouslySetInnerHTML={{__html: t('FOOTER_TEXT')}}/>
            :
            null
          }
          <div className='footer-terms'>
            <a className='link-secondary d-block me-3 muted text-decoration-underline' target='_blank'
               href="https://docs.google.com/document/d/1OcaEyy8iude04mD87ns6wGEpUZU8WZXSlNjW8b1HzJs/edit">{t('PRIVACY')}</a>
            <a className='link-secondary d-block muted text-decoration-underline' target='_blank'
               href="https://docs.google.com/document/d/1p0H79kYZNFwDA3bPT5K-9IS8MsVi__QfRkuMqs676Cg/edit">{t('TERMS')}</a>
          </div>
        </div>
        <div className="col-12 col-xl-3 d-flex justify-content-center justify-content-xl-start py-3">
          <div>
            <div>{t('SUPPORT')}:</div>
            <a href={tg_support_href} className='tg-link' target='_blank'
               rel="noreferrer">@monetizesupport</a>
          </div>
        </div>
        <div className="col-12 col-xl-2 justify-content-end d-flex">
          <a href={tg_href} target='_blank' className='footer-tg' rel="noreferrer">
            <img src={tg} alt="tg" className='tg-icon'/>
            <div className="footer-tg-hide bold">{t('OUR_TG')}</div>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;