import React from 'react';
import {useTranslation} from "react-i18next";

const SectionContent6 = () => {
  const {t} = useTranslation();
  return (
    <>
      <p className='mb-3'>{t('faq:SECTION_4_ITEM_6_TEXT_1')}</p>
      <p>{t('faq:SECTION_4_ITEM_6_TEXT_2')}</p>
    </>
  );
}

export default SectionContent6;