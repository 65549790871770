import React, { FC } from 'react';

interface Props {
  text: [string, string];
  value: string;
  onChange: (value: string) => void;
}

const RegSwitch: FC<Props> = ({ text, value, onChange }) => {
  return (
    <div className="reg-switch_wrap">
      <div className={`reg-switch${value === 'Да' ? ' active' : ''}`}>
        {text.map((item, i) => (
          <div
            key={i}
            onClick={() => onChange(item)}
            className={`reg-switch_item bold-20${value === item ? ' active' : ''}`}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RegSwitch;
