import React, {FC, useCallback} from 'react';
import logo from '../../assets/images/logo.png';
import {Link} from "react-router-dom";
import arrow_l from '../../assets/images/arrow_left.png';
import arrow_r from '../../assets/images/arrow_right.png';
import {useTranslation} from 'react-i18next';
import {tg_support_href} from "../../data/constants";
import Logo from "../../components/Logo";
import {TRole} from "../../utils/rest";

interface Props {
  token: string|null;
  successForm: boolean;
  role: TRole;
}

const RegistrationSider: FC<Props> = ({token, successForm, role}) => {
  const {t} = useTranslation();
  const renderTitle = useCallback(() => {
    if (successForm) return <span>{t('THX_FORM')}</span>
    if (token) return <span dangerouslySetInnerHTML={{__html: t('FORM_PROCESS')}}/>
    return t('SIGN_UP')
  }, [successForm, t]);


  return (
    <div className="registration-sider">
      <div className="registration-sider-bg"/>
      <div className="registration-sider-content">
        <div>
          <Link to='/'>
            <Logo role={role} className='registration-sider-logo'/>
          </Link>
          <div>
            <h2 className='page-title'>{renderTitle()}</h2>
            {token
              ?
              <>
                <div className='muted pt-4'>
                  {t('WRITE_MANAGER')}
                </div>
                <div className='title text-red text-center py-3'>
                  {localStorage.getItem('userId')}
                </div>
                <a href={tg_support_href} target='_blank' className='btn btn-primary me-4'>@monetizesupport</a>
              </>
              :
              null
            }
            {successForm
              ?
              null
              :
              <div
                className='reg-sider-text text-yellow text-24'>
                {token
                  ?
                  <span dangerouslySetInnerHTML={{__html: t('FORM_TEXT_1')}}/>
                  :
                  <span dangerouslySetInnerHTML={{__html: t('FORM_TEXT_2')}}/>
                }
                <img src={arrow_l} alt="arrow" className='reg-sider-arrow d-none d-lg-block'/>
                <img src={arrow_r} alt="arrow" className='reg-sider-arrow d-block d-lg-none'/>
              </div>
            }
          </div>
        </div>
        <Link to='/' className='back-home-link d-none d-lg-block position-relative'>
          {t('BACK_TO_SITE')}
          {successForm ? <img src={arrow_r} alt="arrow" className='reg-sider-home-arrow d-none d-lg-block'/> : null}
        </Link>
      </div>
    </div>
  );
}

export default RegistrationSider;