import {useLayoutEffect, useState} from 'react';

function onEntry(entry: any) {
  entry.forEach((change: any) => {
    const offset = (change?.boundingClientRect?.top - change?.boundingClientRect?.height - 400) < 0
    if (change.isIntersecting && offset) {
      change.target.classList.add('active');
    }
  });
}

const headLogoScale = (scroll: number) => {
  let logo: any = document.querySelector('header .header-logo');
  if (scroll > 1) {
    // let scrolled: any = (1 - (scroll / 200)).toFixed(1);
    // if(scrolled <= 0.5) scrolled = 0.5;
    logo.style.transform = `scale(0.5)`;
  } else {
    logo.style.transform = `scale(1)`;
  }
}
const youtubeBtnBgPosition = (scroll: number) => {
  let options = {
    threshold: [0.5],
    // rootMargin: '-320px',
  };
  let observer = new IntersectionObserver(onEntry, options);
  let elements: any = document.querySelectorAll('.section-3-item');
  for (let elm of elements) {
    observer.observe(elm);
  }

  let land: any = document.querySelector('.youtube-bg');
  let hasClass: boolean = land?.classList?.contains('bg-left');
  if (scroll > 3400 && !hasClass) {
    land?.classList?.add('bg-left')
  } else if (scroll <= 3400) {
    land?.classList?.remove('bg-left')
  }
}


const clamp = (value: number) => Math.max(0, value);

// Check if number is between two values
const isBetween = (value: number, floor: number, ceil: number) => value >= floor && value <= ceil;

export default (ids: string[], offset: number = 0) => {
  const [activeId, setActiveId] = useState('');

  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.pageYOffset;
      const height = document.documentElement.scrollHeight - window.innerHeight;
      youtubeBtnBgPosition(scroll);
      headLogoScale(scroll);
      if (scroll - height > 0) return ids.at(-1);
      const position = ids
        .map((id) => {
          const element = document.getElementById(id);
          if (!element) return {id, top: -1, bottom: -1};
          const rect = element.getBoundingClientRect();
          const top = clamp(rect.top + scroll - offset);
          const bottom = clamp(rect.bottom + scroll - offset);

          return {id, top, bottom};
        })
        .find(({top, bottom}) => isBetween(scroll, top, bottom));
      setActiveId(position?.id || '');
    };
    listener();

    window.addEventListener('resize', listener);
    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('resize', listener);
      window.removeEventListener('scroll', listener);
    };
  }, [ids, offset]);

  return activeId;
};
